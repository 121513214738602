<template>
  <div class="__current_amount">
    <div class="__amount main-display-balance">
      <h3 class="__amount_header">
        <small class="__currency">
          {{ getCountryCurrency }}
        </small>
        <span class="__amount__amount">{{
          ballance
            ? ballance.wallet == null || ballance.wallet == 0
              ? "0.00"
              : ballance.wallet
            : "0.00"
        }}</span>
      </h3>
      <p class="__amount_text notranslate">
        {{ $t("dashboard.wallet.current_balance_subtitle") }}
      </p>
    </div>
    <button
      @click="showLoadBundleModal"
      class="_load_wallet notranslate"
      style="width: fit-content"
    >
      {{ $t("dashboard.wallet.laod_wallets") }}
    </button>
    <!-- CONFIRM PURCHASE MODAL -->
    <div
      class="modal fade"
      id="loadwallet"
      tabindex="-1"
      role="dialog"
      aria-labelledby="group"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered" role="document">
        <div
          class="modal-content d-flex align-items-center"
          v-if="success || error"
        >
          <div class="" v-if="waitingForCallBack == false">
            <successMessage v-if="success" :message="success" />

            <div class="text-danger mt-5 mb-4" v-if="error">
              {{ error }}
            </div>
          </div>
          <div class="row" v-else>
            <div class="col-md-12 mt-5 mb-3">
              <p class="text-center">{{ success }}</p>
              <p class="text-center">
                Click on <strong>"Complete Payment"</strong> after completing
                the prompt
              </p>
              <div
                class="col-md-12"
                style="height: 100px"
                v-loading="true"
              ></div>
            </div>
          </div>
          <div style="display: flex; justify-content: center"></div>
          <div
            class="button-container"
            style="
              width: 100%;
              text-align: center;
              justify-content: center;
              gap: 1rem;
            "
          >
            <button
              v-if="waitingForCallBack == false"
              @click="hideModal"
              class="__close__success__modal"
            >
              {{ $t("misc.button.close") }}
            </button>
            <el-button
              v-if="waitingForCallBack === true"
              @click="handleCheckPayment"
              type="primary"
              style="margin-block: 2rem"
              :loading="checkTransactionLoading"
            >
              Complete Payment
            </el-button>
            <el-button
              :loading="download_receipt_loading"
              type="danger"
              v-if="success && currentUser.profile_type == 1"
              @click="downloadReceipt"
              class="download-receipt"
            >
              <span v-if="countDown != 0" style="color: #ff7878">
                {{ countDownTimer }}
              </span>
              <span>{{ $t("dashboard.wallet.download_receipt") }}</span>
            </el-button>
          </div>
        </div>
        <div v-else class="modal-content">
          <div class="modal-header pt-4 pb-2">
            <h5 class="modal-title __load__wallet" id="loadwalletlabel">
              {{ $t("dashboard.wallet.laod_wallets") }}
            </h5>
            <!-- <button style="border:none; outline:none; background:transparent;" @click="changeSelection" class="__chose__medium">
                            Choose a payment medium
                        </button> -->
          </div>
          <div class="modal-body m-0 p-0">
            <!-- <div class="__select__payment_method" v-if="inSelection">
                            <button @click="switchPaymentMedium('mtn')"  class="__payment__method">
                                <div class="__payment__icon">
                                    <img src="../../../assets/MTNza Icon 0.svg" alt="">
                                </div>
                                <div class="__payment__text">
                                    Pay with MTN Momo
                                </div>
                            </button>
                             <button @click="switchPaymentMedium('zpay')"  class="__payment__method">
                                <div class="__payment__icon">
                                    <img src="../../../assets/korba.png" alt="">
                                </div>
                                <div class="__payment__text">
                                    Pay with Korba
                                </div>
                            </button>
                        </div> -->
            <div
              class="row __form__main_wrapper justify-content-center"
              style="padding-right: 1em; padding-left: 1em"
            >
              <div class="col-md-12">
                <el-form :model="inputFormData" :rules="rules" ref="ruleform">
                  <div class="__inner__form__container">
                    <div class="div" v-if="!toOPT">
                      <div
                        class="form_input_container input___message notranslate"
                        style="width: 100%"
                        v-if="!isFrancoPhone"
                      >
                        <el-form-item
                          :label="$t('dashboard.wallet.network')"
                          prop="network"
                          :key="isFrancoPhone"
                        >
                          <el-select
                            class="notranslate"
                            style="width: 100% !important"
                            v-model="inputFormData.network"
                            filterable
                            :placeholder="$t('dashboard.wallet.select_network')"
                          >
                            <el-option
                              v-for="(network, key) in compNetworks"
                              :key="key"
                              :label="network.name"
                              :value="network.code"
                            >
                            </el-option>
                          </el-select>
                          <template #label></template>
                        </el-form-item>
                      </div>
                      <div
                        class="form_input_container"
                        v-if="inputFormData.network != 'PAYSTACK'"
                      >
                        <el-form-item
                          :label="$t('dashboard.wallet.wallet_number')"
                        >
                          <div style="display: inline-block; width: 100%">
                            <!-- {{ phone_data }} -->
                            <vue-phone-number-input
                              v-model="inputFormData.momo_number"
                              :color="'#f79b31'"
                              clearable
                              size="lg"
                              class="input-model"
                              :required="true"
                              fetch-country
                              :preferred-countries="['GH', 'CI', 'SN', 'NG']"
                              :error="!phone_data.isValid"
                              @update="handlePhoneData($event)"
                              :translations="{
                                countrySelectorLabel: $t(
                                  'misc.phone_input.countrySelectorLabel'
                                ),
                                countrySelectorError: $t(
                                  'misc.phone_input.countrySelectorError'
                                ),
                                phoneNumberLabel: $t(
                                  'misc.phone_input.phoneNumberLabel'
                                ),
                                example: $t('misc.phone_input.example'),
                              }"
                            ></vue-phone-number-input>
                          </div>
                        </el-form-item>
                      </div>
                      <!-- Email -->
                      <div
                        class="form_input_container input___message"
                        style="width: 100%"
                        v-if="isFrancoPhone"
                      >
                        <el-form-item
                          :label="
                            $t(
                              'dashboard.onboarding.verify_email.form.email.label'
                            )
                          "
                          :key="isFrancoPhone"
                          prop="email"
                        >
                          <el-input
                            style="width: 100%"
                            type="text"
                            :placeholder="
                              $t(
                                'dashboard.send_message.message_templates.form.content.email'
                              )
                            "
                            autocomplete="false"
                            v-model="inputFormData.email"
                          ></el-input>
                        </el-form-item>
                      </div>

                      <div class="form_input_container input___message">
                        <el-form-item
                          prop="amount"
                          :label="$t('dashboard.wallet.amount')"
                        >
                          <el-input
                            style="width: 100%"
                            type="text"
                            :placeholder="$t('dashboard.wallet.amount')"
                            autocomplete="false"
                            v-model="inputFormData.amount"
                          ></el-input>
                        </el-form-item>
                      </div>
                    </div>
                    <div v-if="toOPT">
                      <p class="__selected__medium__text">
                        {{ $t("dashboard.wallet.otp_sent") }}
                      </p>
                      <div class="form_input_container input___message">
                        <el-form-item
                          prop="opt"
                          :label="$t('dashboard.wallet.verification_otp')"
                        >
                          <el-input
                            style="width: 100%"
                            type="text"
                            :placeholder="
                              $t('dashboard.wallet.enter_verification_code')
                            "
                            autocomplete="false"
                            v-model="inputFormData.code"
                          ></el-input>
                        </el-form-item>
                      </div>
                      <div class="call-instead-button">
                        <el-button
                          :loading="call_loading"
                          :disabled="countDown != 0"
                          class="call_instead"
                          @click="callForOtp"
                        >
                          <img
                            height="20px"
                            width="20px"
                            src="@/assets/Calling.png"
                            alt="calling"
                            style="margin-right: 10px"
                          />
                          {{ $t("dashboard.wallet.call_instead") }}
                        </el-button>
                        <div v-if="countDown != 0" style="color: #f7921c">
                          {{ countDownTimer }}
                        </div>
                      </div>
                    </div>
                    <!-- ENTER OPT -->
                    <Alert
                      v-if="error"
                      :message="error"
                      :type="'alert-danger'"
                    />
                    <div class="d-flex justify-content-between mb-5 mt-5">
                      <!-- <button  class="cancel" @click="changeSelection">Back</button> -->
                      <button class="cancel notranslate" @click="hideModal">
                        {{ $t("misc.button.cancel") }}
                      </button>
                      <el-button
                        v-if="!toOPT"
                        :loading="loading"
                        type="primary"
                        id="___continue"
                        @click="continueToLoadWallet('ruleform')"
                      >
                        {{ $t("misc.button.send") }}
                      </el-button>
                      <el-button
                        v-if="toOPT"
                        :loading="loading"
                        type="primary"
                        id="___continue"
                        @click="loadWallet('ruleform')"
                      >
                        {{ $t("dashboard.wallet.verify_otp") }}
                      </el-button>
                    </div>
                  </div>
                </el-form>
              </div>
            </div>
            <!-- <div v-if="inSelection" class="" style="width:100%;text-align:center; margin-bottom:2em;" >
                            <button  class="cancel" @click="hideModal">Cancel</button>
                        </div> -->
          </div>
        </div>
      </div>
    </div>
    <el-dialog
      :visible.sync="toggleReceiptModal"
      :center="true"
      :width="dynamicWidth"
    >
      <successMessage :message="'Payment Successful'" />
      <div class="paystack-modal" slot="footer">
        <el-button
          type="primary"
          @click="toggleReceiptModal(false)"
          class="__close__success__modal"
        >
          {{ $t("misc.button.close") }}
        </el-button>
        <el-button
          :disabled="countDown != 0"
          :loading="download_receipt_loading"
          type="danger"
          @click="downloadReceipt"
          class="download-receipt"
        >
          <span v-if="countDown != 0" style="color: #ff7878">
            {{ countDown }}
          </span>
          <span>{{ $t("dashboard.wallet.download_receipt") }}</span></el-button
        >
      </div>
    </el-dialog>
  </div>
</template>
<script src="https://js.paystack.co/v2/inline.js"></script>
<script>
import $ from "jquery";
import store from "@/state/store.js";
import Echo from "laravel-echo";
import Storage from "@/services/storage";
const storage = new Storage();
import Pusher from "pusher-js";
import { Notification } from "element-ui";

export default {
  name: "transaction-history-header",
  data() {
    var validateAmount = (rule, value, callback) => {
      //  if not number return error
      if (isNaN(value)) {
        callback(new Error(this.$t("dashboard.wallet.must_number")));
      }
      if (this.isFrenchUser) {
        if (value < 10000) {
          callback(
            new Error(
              this.$t("dashboard.wallet.min_amount", {
                amount: 10000,
              })
            )
          );
        } else {
          callback();
        }
      } else {
        if (window.location.href === "https://preproduction.mnotify.com/") {
          return;
        }
        if (value < 10) {
          callback(
            new Error(
              this.$t("dashboard.wallet.min_amount", {
                amount: 10,
              })
            )
          );
        } else {
          callback();
        }
      }
    };
    return {
      phone_data: {},
      networks: [
        {
          name: "MTN",
          code: "MTN",
        },
        {
          name: "AirtelTigo",
          code: "AIR",
        },
        {
          name: "Telecel",
          code: "VOD",
        },
        {
          name: "Orange",
          code: "ORANGE",
        },
        {
          name: "Paystack",
          code: "PAYSTACK",
        },
      ],
      paymentMedium: {
        logo: "",
        text: "",
        numberLabel: "",
        numberPlaceholder: "",
        amountLabel: "",
        depositPlaceholder: "",
        optLabel: "",
        optPlaceholder: "",
        type: "",
      },
      inSelection: false,
      toOPT: false,
      success: "",
      error: "",
      waitingForCallBack: false,
      loading: false,
      transactionId: null,
      payment_method: null,
      checkTransactionLoading: false,
      inputFormData: {
        momo_number: "",
        amount: "",
        //code:'',
        network: "",
        email: "",
      },
      rules: {
        momo_number: [
          {
            required: true,
            message: this.$t("misc.form.required"),
            pattern: /^[0-9]{10}$/g,
            trigger: "change",
          },
        ],
        amount: [
          {
            required: true,
            message: this.$t("misc.form.required"),
            trigger: "blur",
          },
          {
            validator: validateAmount,
            trigger: "blur",
          },
        ],
        network: [
          {
            required: !this.isFrancoPhone,
            message: this.$t("misc.form.required"),
            trigger: "blur",
          },
        ],
        email: [
          {
            required: false,
            message: this.$t("misc.form.required"),
            trigger: "blur",
          },
        ],
      },
      //laravel echo
      echo: null,
      timer: 0,
      countDown: 0,
      call_loading: false,
      download_receipt_loading: false,
    };
  },
  created() {
    store.dispatch("wallet/accountBalance");
  },
  unmounted() {
    clearInterval(this.timer);
    // remove event listener
    window.removeEventListener("beforeunload", (event) => {
      // Display a confirmation message
      event.preventDefault(); // Necessary to trigger the confirmation dialog
      event.returnValue = ""; // Some browsers require this property to be set
    });

    // remove echo listener
  },
  computed: {
    getCountryCurrency() {
      const country = storage.getSavedState("currentUser").country;
      return country == "CI" || country == "SN" ? "CFA" : "GHS";
    },
    isFrenchUser() {
      return storage.getSavedState("currentUser").country == "CI" ||
        storage.getSavedState("currentUser").country == "SN"
        ? true
        : false;
    },
    isFrancoPhone() {
      return (
        this.phone_data.countryCode === "CI" ||
        this.phone_data.countryCode === "SN"
      );
    },
    compNetworks() {
      return this.networks.filter((network) => {
        if (this.isFrancoPhone) {
          return network.code == "MTN" || network.code == "ORANGE";
        }
        // filter out orange network for other countries
        return network.code != "ORANGE";
      });
    },
    ballance() {
      return store.getters["wallet/ballance"];
    },
    countDownTimer() {
      return this.countDown;
    },
    currentUser() {
      return store.getters["auth/currentUser"];
    },
    dynamicWidth() {
      if (screen.width < 768) {
        return "95%";
      } else {
        return "40%";
      }
    },
    toggleReceiptModal: {
      set(val) {
        store.dispatch("wallet/setReceiptModal", val);
      },
      get() {
        return store.getters["wallet/getReceiptModal"];
      },
    },

    isCorporate() {
      return this.currentUser().profile_type == 1 ? true : false;
    },
  },
  methods: {
    verifyStatus() {
      let payloadData = {
        wallet: this.phone_data.formatNational.replace(/\s/g, ""),
      };
      store
        .dispatch("wallet/verifyStatus", payloadData)
        .then((response) => {
          if (!response.data) {
            this.toOPT = true;
            this.startCountDown(45);
            this.loading = false;
            return;
          }
          this.depositByMtn();
        })
        .catch((err) => {});
    },
    verifyOTP() {
      let payloadData = {
        code: this.inputFormData.code,
        wallet: this.phone_data.formatNational.replace(/\s/g, ""),
      };

      store
        .dispatch("wallet/verifyOTP", payloadData)
        .then(() => {
          this.depositByMtn();
        })
        .catch((error) => {
          this.error = error.response.data.message;
        })
        .finally(() => {
          this.loading = false;
        });
    },
    //  inputFormData (){
    //     reactive({
    //         momo_number:'',
    //     })
    //  },
    showLoadBundleModal() {
      this.success = null;
      this.error = null;
      this.loading = false;
      this.waitingForCallBack = false;
      $("#loadwallet").modal({
        backdrop: "static",
        keyboard: false,
        show: true,
      });
      this.inputFormData = {
        momo_number: "",
        amount: "",
        code: "",
        payment_method: "",
        email: this.currentUser.email,
      };
    },
    hideModal() {
      $("#loadwallet").modal("hide");
      this.inSelection = true;
      this.toOtp = false;
      this.waitingForCallBack = false;
      this.paymentMedium = {
        logo: "",
        text: "",
        numberLabel: "",
        numberPlaceholder: "",
        amountLabel: "",
        depositPlaceholder: "",
        optLabel: "",
        optPlaceholder: "",
        type: "",
      };
      if (
        this.error == "Connection Timeout, Try again." ||
        this.error == "Connection Timeout, Try again."
      ) {
        // Show feedback modal
        // store.dispatch('modal/showFeedbackModal', true)
        store.dispatch("modal/showCategoriesModal", true);
      }
      location.reload();
    },
    changeSelection() {
      (this.inSelection = true), (this.error = "");
      this.success = "";
      this.toOPT = false;
      this.rules = this.rules;
      this.inputFormData = {
        momo_number: "",
        amount: "",
        code: "",
        payment_method: "",
      };
    },
    switchPaymentMedium(type) {
      this.inSelection = false;
      if (type == "mtn") {
        this.inputFormData.payment_method = "mtn_dt_v1";
        this.paymentMedium = {
          logo: require("../../../assets/MTNza Icon 0.svg"),
          text: "MTN Momo",
          numberLabel: "MTN Momo Number",
          numberPlaceholder: "Enter your momo number",
          amountLabel: "Deposit amount",
          depositPlaceholder: "Enter amount to deposit",
          optLabel: "OTP code",
          optPlaceholder: "Enter OTP code",
          type: "mtn",
        };
      } else {
        this.inputFormData.payment_method = "korba_dt_v1";
        this.paymentMedium = {
          logo: require("../../../assets/korba.png"),
          text: "Korba",
          numberLabel: "Korba Number",
          numberPlaceholder: "Enter your Korba number",
          amountLabel: "Deposit amount",
          depositPlaceholder: "Enter amount to deposit",
          optLabel: "OTP code",
          optPlaceholder: "Enter OTP code",
          type: "zpay",
        };
      }
    },
    depositByMtn() {
      const { momo_number, amount, network } = this.inputFormData;
      store
        .dispatch("wallet/depositByMtn", {
          momo_number: "0" + this.phone_data.nationalNumber.replace(/\s/g, ""),
          amount: amount,
          network: network,
        })
        .then((response) => {
          if (response) {
            this.loading = false;
            this.success =
              "Follow the prompt on your phone to complete the transaction.";
            this.waitingForCallBack = true;
            this.transactionId = response.data.data.externalId;
            this.payment_method = response.data.data.payment_method;
            this.echo = new Echo({
              broadcaster: "pusher",
              key: "5500560c93f4be2fabfc",
              cluster: "eu",
              namespace: "",
              encrypted: true,
              authEndpoint:
                process.env.VUE_APP_ENGINE_URL + "/api/broadcasting/auth",
              auth: {
                headers: {
                  Authorization: "Bearer " + storage.getSavedState("token"),
                },
              },
            });

            this.echo
              .channel("private-wallet-transaction")
              .listen(".walletTopup.created", (data) => {
                if (data.transaction.trans_id === this.transactionId) {
                  if (data.transaction.status === "FAILED") {
                    this.waitingForCallBack = false;
                    this.success = null;
                    this.error = "Transaction Failed";
                    // track mixpanel event
                    this.$mixpanel.track("Wallet Topup Failed", {
                      Amount: this.inputFormData.amount,
                      Method: this.inputFormData.payment_method,
                      User: this.currentUser.id,
                      Error: data.transaction.error,
                    });

                    //  for franco
                    if (
                      this.$store.getters["auth/currentUser"].country === "CI"
                    ) {
                      this.$mixpanelFranco.track("Wallet Topup Failed", {
                        Amount: this.inputFormData.amount,
                        Method: this.inputFormData.payment_method,
                        User: this.currentUser.id,
                        Error: data.transaction.error,
                      });
                    }
                    // Show feedback modal
                    store.dispatch("modal/showFeedbackModal", true);
                  } else if (data.transaction.status === "SUCCESS") {
                    this.waitingForCallBack = false;
                    this.success =
                      "Wallet deposit successful.  Please select any of the bundle offers to proceed";
                    this.error = null;
                    // Show Categories modal
                    store.dispatch("modal/showCategoriesModal", true);
                  } else if (data.transaction.status === "SUCCESS") {
                    this.waitingForCallBack = false;
                    this.success = "Transaction Successful.";
                    this.error = null;
                    // track mixpanel event
                    this.$mixpanel.track("Wallet Topup Successful", {
                      Amount: this.inputFormData.amount,
                      Method: this.inputFormData.payment_method,
                      User: this.currentUser.id,
                    });
                    // for franco
                    if (
                      this.$store.getters["auth/currentUser"].country === "CI"
                    ) {
                      this.$mixpanelFranco.track("Wallet Topup Successful", {
                        Amount: this.inputFormData.amount,
                        Method: this.inputFormData.payment_method,
                        User: this.currentUser.id,
                      });
                    }
                    // Fetch new balance here
                    store.dispatch("wallet/accountBalance");
                    store.dispatch("wallet/walletBundleHistory");
                  } else {
                    setTimeout(() => {
                      this.waitingForCallBack = false;
                      this.success = null;
                      this.error =
                        "There was a problem processing your request, try again.";
                      // track mixpanel event
                      this.$mixpanel.track("Wallet Topup Failed", {
                        Amount: this.inputFormData.amount,
                        Method: this.inputFormData.payment_method,
                        User: this.currentUser.id,
                        Error:
                          "There was a problem processing your request, try again.",
                      });

                      // for franco
                      if (
                        this.$store.getters["auth/currentUser"].country === "CI"
                      ) {
                        this.$mixpanelFranco.track("Wallet Topup Failed", {
                          Amount: this.inputFormData.amount,
                          Method: this.inputFormData.payment_method,
                          User: this.currentUser.id,
                          Error:
                            "There was a problem processing your request, try again.",
                        });
                      }
                    }, 60000);
                  }
                }
              });
            //   Set timeout for listening to response for prompt completion
            setTimeout(() => {
              this.waitingForCallBack = false;
              this.success = null;
              this.error = "Connection Timeout, Try again.";
              // track mixpanel event
              this.$mixpanel.track("Wallet Topup Failed", {
                Amount: this.inputFormData.amount,
                Method: this.inputFormData.payment_method,
                User: this.currentUser.id,
                Error: "Connection Timeout, Try again.",
              });

              // for franco
              if (this.$store.getters["auth/currentUser"].country === "CI") {
                this.$mixpanelFranco.track("Wallet Topup Failed", {
                  Amount: this.inputFormData.amount,
                  Method: this.inputFormData.payment_method,
                  User: this.currentUser.id,
                  Error: "Connection Timeout, Try again.",
                });
              }
            }, 125000);
          }
        })
        .catch((err) => {
          this.loading = false;
          if (err.response?.data == undefined) {
            this.error = "An error occured try again.";
          }
          if (err.response && err.response.status === 404) {
            this.error = Object.values(err.response.data.data)[0][0];
          }
          if (err.response && err.response.status === 500) {
            this.error = "Server error try again";
          }
        })
        .finally(() => {
          window.addEventListener("beforeunload", (event) => {
            // Display a confirmation message
            event.preventDefault(); // Necessary to trigger the confirmation dialog
            event.returnValue = ""; // Some browsers require this property to be set
          });
        });
    },
    continueToLoadWallet(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid && this.phone_data.isValid) {
          this.loading = true;
          this.success = null;
          this.error = null;

          if (this.inputFormData.network === "PAYSTACK") {
            this.loading = false;
            this.OtherMethods();
            return;
          }

          if (this.isFrancoPhone) {
            this.loading = false; // to reset the button loading
            // this.OtherMethods();
            this.FrancoPhoneMethods();
            return;
          }
          // Check status whether phone number is activated or not
          this.verifyStatus();
        } else {
          if (this.inputFormData.network === "PAYSTACK") {
            this.loading = false;
            this.OtherMethods();
            return;
          }
          return false;
        }
      });
    },
    loadWallet(formName) {
      this.loading = true;
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.verifyOTP();
        } else {
          this.loading = false;
          return false;
        }
      });
    },
    callForOtp() {
      this.call_loading = true;
      let data = {
        phone: this.currentUser.phone,
        otp_method: "voice",
        type: 1,
      };
      store
        .dispatch("auth/voiceActivation", data)
        .then(() => {
          this.$notify({
            title: "Success",
            message: "Call successful",
            type: "success",
          });
          this.$mixpanel.track("Used Voice OTP Wallet", {
            Phone: this.currentUser.phone,
          });

          // for franco
          if (this.$store.getters["auth/currentUser"].country === "CI") {
            this.$mixpanelFranco.track("Used Voice OTP Wallet", {
              Phone: this.currentUser.phone,
            });
          }

          this.startCountDown(60);
        })
        .catch((response) => {
          this.$notify({
            title: "Error",
            message: response.data.message,
            type: "error",
          });
        })
        .finally(() => {
          this.call_loading = false;
        });
    },
    startCountDown(timer = 60) {
      this.countDown = timer;
      // Clear timer if it exists
      if (this.timer) {
        clearInterval(this.timer);
      }
      this.timer = setInterval(() => {
        this.countDown--;
        if (this.countDown == 0) {
          clearInterval(this.timer);
        }
      }, 1000);
    },
    async FrancoPhoneMethods() {
      try {
        this.loading = true;
        await this.$store.dispatch("wallet/processFrancoPhonePayment", {
          email: this.inputFormData.email,
          amount: this.inputFormData.amount,
          network: this.inputFormData.network,
          phone: this.phone_data.formattedNumber.replace(/\s/g, ""),
        });
        this.$notify({
          title: "Success",
          message: "A payment link has been sent to your email",
          type: "success",
        });
        this.hideModal();
      } catch (error) {
        this.$notify({
          title: "Error",
          message: error.response.data.message,
          type: "error",
        });
      } finally {
        this.loading = false;
      }
    },
    OtherMethods() {
      var popup = new PaystackPop();
      const currentUser = this.$store.getters["auth/currentUser"];
      const reference =
        "" + Math.floor(Math.random() * 1000000000 + 1) + "_" + Date.now();
      popup.newTransaction({
        key: process.env.VUE_APP_PAYSTACK_KEY,
        email: currentUser.email,
        amount: ["preproduction", "localhost"].includes(window.location.href)
          ? 1
          : this.inputFormData.amount * 100,
        currency: "GHS",
        ref: reference,
        onLoad: (data) => {
          let payloadData = {
            momo_number: data.customer.email,
            amount: this.inputFormData.amount,
            network: this.inputFormData.network,
            ref: reference,
          };
          this.createTransaction(payloadData);
        },
        onSuccess: function (response) {
          console.log(response);
          var reference = response.reference;
          response.status == "success";
          Notification.success({
            title: "success",
            message: response.message,
          });
          store
            .dispatch("wallet/checkPaytackChargeNumber", {
              reference: reference,
            })
            .then((phoneNumber) => {
              store
                .dispatch("wallet/checkPaystackStatus", {
                  reference: reference,
                  phone: phoneNumber,
                })
                .then(() => {
                  store.dispatch("wallet/accountBalance");
                  store.dispatch("wallet/walletBundleHistory");
                });
            });

          if (store.getters["auth/currentUser"].profile_type == 1) {
            store.dispatch("wallet/setTransactionID", reference);
            store.dispatch("wallet/setReceiptModal", true);
          }
        },
        onCancel: function (res) {
          response.status == "error";
          Notification.error({
            tittle: "error",
            message: response.message,
          });
          store
            .dispatch("wallet/checkPaytackChargeNumber", {
              reference: reference,
            })
            .then((phoneNumber) => {
              console.log("Phone number", phoneNumber);
              store
                .dispatch("wallet/checkPaystackStatus", {
                  reference: reference,
                  phone: phoneNumber,
                })
                .then(() => {
                  store.dispatch("wallet/accountBalance");
                  store.dispatch("wallet/walletBundleHistory");
                });
            });
        },
        onError: function (response) {
          response.status == "error";
          Notification.error({
            tittle: "error",
            message: response.message,
          });

          store
            .dispatch("wallet/checkPaytackChargeNumber", {
              reference: reference,
            })
            .then((phoneNumber) => {
              console.log("Phone number", phoneNumber);
              store
                .dispatch("wallet/checkPaystackStatus", {
                  reference: reference,
                  phone: phoneNumber,
                })
                .then(() => {
                  store.dispatch("wallet/accountBalance");
                  store.dispatch("wallet/walletBundleHistory");
                });
            });
        },
      });

      // handler.openIframe();
    },
    createTransaction(response) {
      $("#loadwallet").modal("hide");
      store
        .dispatch("wallet/depositByMtn", response)
        .then(() => {
          console.log("Transaction started");
        })
        .catch((err) => {
          this.error = err.data.message;
        });
    },
    downloadReceipt() {
      let transaction_id =
        this.transactionId == null
          ? store.getters["wallet/getTransactionID"]
          : this.transactionId;
      this.download_receipt_loading = true;
      let currentUser = store.getters["auth/currentUser"];
      let filename = `${currentUser.name
        .trim()
        .replace(/\s+/g, "_")}_wallet_topup_receipt_${Date.now()}`;
      let body = {
        from: "mNotify (BMS)",
        to:
          currentUser.name +
          "\n" +
          this.phone_data.formatNational.replace(/\s/g, ""),
        number: transaction_id,
        items: [
          {
            name: this.inputFormData.network,
            quantity: 1,
            unit_cost: this.inputFormData.amount,
          },
        ],
        header: "Wallet Topup",
        currency: "GHS",
        item_header: "Payment Method",
        unit_cost_header: "Amount",
        balance_title: "Deposit Amount",
        logo: "https://mnotifybms.com/wp-content/uploads/2023/04/bms-logo.png",
        amount_header: "Total Amount",
      };
      const payload = {
        filename: filename,
        body: JSON.stringify(body),
      };
      store
        .dispatch("wallet/generateReceipt", payload)
        .then((res) => {
          this.startCountDown(60);
        })
        .finally(() => {
          this.download_receipt_loading = false;
        });
    },
    handlePhoneData(data) {
      this.phone_data = data;
      if (data.isValid) {
        this.inputFormData.momo_number = data.formatNational.replace(/\s/g, "");
      }
    },
    handleCheckPayment() {
      let payload = {
        transaction_id: this.transactionId,
        payment_method: this.payment_method,
      };
      this.checkTransactionLoading = true;
      store
        .dispatch("wallet/checkPaymentStatus", payload)
        .then((data) => {
          this.waitingForCallBack = false;
          this.success =
            "Wallet deposit successful.  Please select any of the bundle offers to proceed";
          this.error = null;
          // fetch new balance
          store.dispatch("wallet/accountBalance");
          store.dispatch("wallet/walletBundleHistory");
          this.$notify({
            message: this.$t("payment-completed"),
            type: "success",
          });
        })

        .catch((err) => {
          this.$notify({
            message: err.response.data.data.error ?? this.$t("payment-failed"),
            type: "error",
            title: this.$t("misc.toast.error"),
          });
        })
        .finally(() => {
          this.checkTransactionLoading = false;
        });
    },
  },
};
</script>

<style scoped lang="scss">
@import url("../../../styles/wallet/__header.scss");

.main-display-balance {
  width: 100%;
  text-align: center;
}

.call_instead {
  color: #f7921c;
  outline: none;
  border: none;
  background: transparent;
  transition: all 0.3s ease-in-out;
}

.call_instead:hover {
  color: #f99829;
  outline: none;
  border: none;
  background: transparent;
  transition: all 0.3s ease-in-out;
}

.call_instead:disabled {
  color: #c8c6c6;
  outline: none;
  border: none;
  background: transparent;
}

.call-instead-button {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.download-receipt {
  margin-bottom: 1rem;
}

.button-container button {
  margin-inline: 2rem;
}

.paystack-modal {
  display: flex;
  justify-content: center;
  width: 100%;
}
</style>
